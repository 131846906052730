import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
} from "styled-system";

import styled from "styled-components";

const Box = styled.div`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  ${(props) => {
    if (props.transform) {
      return `transform: ${props.transform};`;
    }
  }}
  ${(props) => {
    if (props.transformOrigin) {
      return `transform-origin: ${props.transformOrigin};`;
    }
  }}
  ${(props) => {
    if (props.transition) {
      return `transition: ${props.transition};`;
    }
  }}
  ${(props) => {
    if (props.transitionDelay) {
      return `transition-delay: ${props.transitionDelay}ms;`;
    }
  }}
  ${(props) => {
    if (props.pointerEvents) {
      return `pointer-events: ${props.pointerEvents};`;
    }
  }}
  ${(props) => {
    if (props.gap) {
      return `gap: ${props.gap};`;
    }
  }}
  ${(props) => {
    if (props.fadeInRollover) {
      return `opacity: 0.5;
              transition :opacity 300ms;
              &:hover {
                opacity: 1;
              }`;
    }
  }}
  ${(props) => {
    if (props.fadeOutRollover) {
      return `opacity: 1;
              transition: opacity 300ms;
              &:hover {
                opacity: 0.5;
              }`;
    }
  }}
  ${(props) => {
    if (props.scaleUpRollover) {
      return `transform: scale3d(100%,100%,100%);
              transition: transform 700ms cubic-bezier(0.16, 1, 0.3, 1);
              &:hover {
                transform: scale3d(102%,102%,102%);
              }`;
    }
  }}
  ${(props) => {
    if (props.hover)
      return `
    &:hover {
      ${props.hover}
    }
    `;
  }}
  @media (max-height: 639px) {
    transition-delay: 0;
  }
`;

export default Box;
